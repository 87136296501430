var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-tabbar",
        {
          attrs: { "active-color": "#ee0a24" },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _vm.type === 1
            ? _c(
                "van-tabbar-item",
                {
                  attrs: {
                    name: "plan",
                    icon: "flag-o",
                    replace: "",
                    to: _vm.getJourneyUri(),
                  },
                },
                [_vm._v("方案详情")]
              )
            : _vm._e(),
          _vm.type === 2 && _vm.showGroup
            ? _c(
                "van-tabbar-item",
                {
                  attrs: {
                    name: "collection",
                    icon: "home-o",
                    replace: "",
                    to: _vm.collectionUri(),
                  },
                },
                [_vm._v("收款详情")]
              )
            : _vm._e(),
          _vm.showGroup
            ? _c(
                "van-tabbar-item",
                {
                  attrs: {
                    name: "price",
                    icon: "friends-o",
                    replace: "",
                    to: _vm.getGroupUri(),
                  },
                },
                [_vm._v("价格详情")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "keep-alive",
        [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
        1
      ),
      !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }