<template>
    <div>
        <van-tabbar v-model="active" active-color="#ee0a24">
            <!-- type = 1 方案详情-->
            <van-tabbar-item v-if="type === 1" name="plan" icon="flag-o" replace
                :to="getJourneyUri()">方案详情</van-tabbar-item>
            <!-- type = 2 收款详情-->
            <van-tabbar-item v-if="type === 2 && showGroup" name="collection" icon="home-o" replace :to="collectionUri()">收款详情</van-tabbar-item>
            <van-tabbar-item v-if="showGroup" name="price" icon="friends-o" replace
                :to="getGroupUri()">价格详情</van-tabbar-item>
        </van-tabbar>
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import { mapActions, mapMutations, mapState } from "vuex";
import Api from "@/components/Api";
import PayApi from "@/components/Api/pay";
export default {
    name: "Collection",
    data() {
        // 这里存放数据
        return {
            groupUri: '',
            active: 'collection'
        }
    },
    // import 引入的组件需要注入到对象中才能使用
    components: {
    },
    props: {},
    // 方法集合
    methods: {
        ...mapMutations('collection', ['SET_COLLECTIONID', 'SET_CIPHERTEXT', 'SET_PLANID', 'SET_MAINID', 'SET_PRICEDISPLA', 'SET_TYPE', 'SET_PROGRAMMEID', "SET_SHOW_GROUP","SET_PAYID","SET_IS_PAY","SET_INQUIRYID"]),
        ...mapActions('collection', ['JUMP_TO_DEFAULT_LINK']),
        getRouteValue() {
            if (this.$route.query.type) {
                // 收款设置项
                if (Number(this.$route.query.type) === 2 && this.$route.query.collectionId && this.$route.query.ciphertext) {
                    let collectionId = this.$route.query.collectionId
                    let ciphertext = this.$route.query.ciphertext
                    this.SET_COLLECTIONID(collectionId)
                    this.SET_CIPHERTEXT(ciphertext)
                    this.SET_PAYID(this.$route.query.payId)
                    new PayApi("pay").getInfo(collectionId).then(res => {
                        if (res.status === 200) {
                            if (res.data.collectionType == 4) {
                                this.SET_SHOW_GROUP(false)
                            } else {
                                this.SET_SHOW_GROUP(true)
                            }
                        }
                    })
                }
                // 方案详情项
                else if (Number(this.$route.query.type) === 1) {
                    // budgetMainId
                    let budgetMainId = this.$route.query.budgetMainId
                    // planId
                    let planId = this.$route.query.planId
                    // priceDisplay
                    let priceDisplay = this.$route.query.priceDisplay
                    // programmeId
                    let programmeId = this.$route.query.programmeId
                    let isPay = this.$route.query.isPay
                    let inquiryId = this.$route.query.inquiryId
                    if(inquiryId) {
                        this.SET_INQUIRYID(inquiryId)
                    }
                    if (isPay) {
                        this.SET_IS_PAY(isPay)
                    }
                    if (priceDisplay) {
                        let _data = JSON.parse(decodeURIComponent(priceDisplay))
                        this.SET_PRICEDISPLA(_data)
                    }
                    this.SET_MAINID(budgetMainId)
                    this.SET_PLANID(planId)
                    this.SET_PROGRAMMEID(programmeId)
                }
                this.SET_TYPE(Number(this.$route.query.type))
                // 默认跳转
                this.JUMP_TO_DEFAULT_LINK()
            }
        },

    },
    // 计算属性 类似于 data 概念
    computed: {
        ...mapState('collection', ['planId', 'budgetMainId', 'collectionId', 'ciphertext', 'type', 'showGroup']),
        getGroupUri() {
            return function () {
                return `/Collection/PlanShare?planId=${this.planId}&budgetMainId=${this.budgetMainId}&priceDisplay=-1`
            }
        },
        collectionUri() {
            return function () {
                if (this.collectionId && this.ciphertext && this.$route.path !== '/PlanCollection') {
                    return `/PlanCollection?collectionId=${this.collectionId}&ciphertext=${this.ciphertext}`
                }
            }
        },
        getJourneyUri() {
            return function () {
                return `/Collection/PlanDetail?planId=${this.planId}&budgetMainId=${this.budgetMainId}`
            }
        }
    },
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
    },
    // 生命周期 - 挂载之前
    beforeMount() {
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
        this.getRouteValue()
        // this.JUMP_TO_DEFAULT_LINK()
    },
    // 生命周期 - 更新之前
    beforeUpdate() {
    },
    // 生命周期 - 更新之后
    updated() {
    },
    // 生命周期 - 销毁之前
    beforeDestroy() {
    },
    // 生命周期 - 销毁完成
    destroyed() {
    },
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {
    },
    //离开的时候触发
    deactivated() {
    },
}
</script>

<style scoped></style>
